<template lang="html">
  <div class="loading-wrapper">
    <transition mode="out-in">
      <span v-if="loading">Loading...</span>
      <div v-else class="loaded-content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s;
}
.loading-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.loaded-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
