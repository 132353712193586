<script>
import VueSelectize from '@components/VueSelectize'

export default {
  functional: true,
  render (h, context) {
    return h('div', {
      class: 'dlg-vue-selectize'
    }, [h(VueSelectize, context.data, context.children)])
  }
}
</script>

<style lang="scss">
.dlg-vue-selectize {
  .selectize-control.single {
    max-height: 24px;

    .selectize-input {
      padding: 1px 4px;
      padding-right: 24px;
      min-height: 24px;
      max-height: 24px;

      &:after {
        right: 6px;
        margin-top: -2px;
      }
    }

    .selectize-dropdown {
      width: auto !important;
    }

    .selectize-dropdown [data-selectable], .selectize-dropdown .optgroup-header {
       white-space: nowrap;
    }
  }
}
</style>
